import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  makeStyles,
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
  Modal,
} from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  RewardCampaignInput,
  useUpdateRewardCampaignMutation,
  useGetAllChamberMembersQuery,
  UserType,
  useGetRewardCampaignQuery,
  useEndRewardCampaignMutation,
  GetAllRewardCampaignsForChamberDocument,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import useRedirect from "../../common/Hooks/useRedirect";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import RewardCampaignEndDatePicker from "./RewardCampaignEndDatePicker";
import Loader from "../../common/Loader/Loader";
import { useLocation } from "react-router-dom";
import omitDeep from "omit-deep-lodash";
import ChamberMemberSelector from "../ChamberMemberAdmin/ChamberMemberSelector";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
    minHeight: "85vh",
    paddingBottom: "40px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  notificationDescriptionInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  descriptionPreviewText: {
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "600px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const validationSchema = yup.object({
  sponsoringChamberMemberId: yup
    .string()
    .required("Sponsoring member is required"),
  campaignName: yup.string().required("Reward campaign name is required"),
  prizeDescription: yup
    .string()
    .required("Reward campaign prize description is required"),
  startDateUtcMilli: yup
    .number()
    .required("Reward campaign start date is required")
    .min(0, "Reward campaign start date is required"),
  endDateUtcMilli: yup
    .number()
    .required("Reward campaign end date is required")
    .min(Date.now(), "Reward campaign end date is required"),
});

const UpdateRewardCampaignCampaign = (): ReactElement => {
  const classes = useStyles();
  const CHARACTER_LIMIT_DESCRIPTION = 250;

  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customError, setCustomError] = useState(
    "An Error Occured, Please try again."
  );
  const [invalidError, setInvalidError] = useState(false);
  const [updateRewardCampaign] = useUpdateRewardCampaignMutation();
  const redirectToRewardCampaignList = useRedirect(
    RedirectUri.activeChamberReward,
    {
      showToast: true,
      toastText: enumStrings.rewardCampaign.updateToastText,
    }
  );

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const [endCampaignModalOpen, setEndCampaignModalOpen] = useState(false);

  const location = useLocation();
  const rewardCampaignId = useMemo(() => {
    return (location.state as { rewardCampaignId: string }).rewardCampaignId;
  }, [location.state]);

  const { data: rewardCampaignInfo } = useGetRewardCampaignQuery({
    variables: {
      id: rewardCampaignId,
    },
  });

  const intitalValuesMemo = useMemo(() => {
    const dataObj = rewardCampaignInfo?.getRewardCampaign;
    const initialValues: RewardCampaignInput = {
      startDateUtcMilli: dataObj?.startDateUtcMilli || 0,
      endDateUtcMilli: dataObj?.endDateUtcMilli || 0,
      sponsoringChamberMemberId: dataObj?.sponsoringChamberMemberId || "",
      campaignName: dataObj?.campaignName || "",
      prizeDescription: dataObj?.prizeDescription || "",
    };
    return omitDeep(initialValues, "__typename");
  }, [rewardCampaignInfo?.getRewardCampaign]) as unknown as RewardCampaignInput;

  const formik = useFormik({
    initialValues: intitalValuesMemo,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (input: RewardCampaignInput) => {
      setLoader(true);
      try {
        setButtonDisabled(true);

        if (input.startDateUtcMilli > input.endDateUtcMilli) {
          setInvalidError(true);
          setCustomError(
            `Unable to update reward campaign. End date must be after start date.`
          );
          setLoader(false);
          setButtonDisabled(false);
          return;
        }

        const rewardCampaignCreation = await updateRewardCampaign({
          variables: {
            id: rewardCampaignId,
            input: {
              startDateUtcMilli: input.startDateUtcMilli,
              endDateUtcMilli: input.endDateUtcMilli,
              sponsoringChamberMemberId: input.sponsoringChamberMemberId,
              campaignName: input.campaignName,
              prizeDescription: input.prizeDescription,
            },
          },
          refetchQueries: [
            { query: GetAllRewardCampaignsForChamberDocument },
            "getAllRewardCampaignsForChamber",
          ],
          awaitRefetchQueries: true,
        });
        const updateionResult =
          rewardCampaignCreation.data?.updateRewardCampaign;
        if (updateionResult?.campaignUpdatedSuccessfully === false) {
          setInvalidError(true);
          setCustomError(`Unable to update reward campaign`);
          setLoader(false);
          setButtonDisabled(false);
          return;
        }

        setLoader(false);
        redirectToRewardCampaignList();
      } catch (error) {
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  const updateRewardCampaignCampaignObj = useMemo(() => {
    return enumStrings.groups[UserType.ChamberAdmin][RedirectUri.updateReward];
  }, []);

  const { data: allChamberMemberData } = useGetAllChamberMembersQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip: !chamberInfo?.id,
    // fetchPolicy: "cache-and-network",
  });

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (
      allChamberMemberData?.getAllChamberMembers &&
      rewardCampaignInfo?.getRewardCampaign
    ) {
      setShowLoader(false);
    }
  }, [
    allChamberMemberData?.getAllChamberMembers,
    rewardCampaignInfo?.getRewardCampaign,
  ]);

  return (
    <div>
      {endCampaignModalOpen && (
        <EndCampaignModal
          campaignId={rewardCampaignId}
          closeModal={() => setEndCampaignModalOpen(false)}
        />
      )}
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{updateRewardCampaignCampaignObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {updateRewardCampaignCampaignObj.description}
          </span>
          <div style={{ paddingTop: "10px" }} />
        </div>
      </div>
      <div className={classes.content}>
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Loader />
          </div>
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
            <ChamberMemberSelector
              allChamberMemberData={allChamberMemberData}
              chamberMemberId={
                formik.values.sponsoringChamberMemberId === ""
                  ? null
                  : formik.values.sponsoringChamberMemberId
              }
              setChamberMemberId={(chamberMemberId: string) => {
                formik.setFieldValue(
                  "sponsoringChamberMemberId",
                  chamberMemberId || ""
                );
              }}
              isRewardCampaignSelector
            />
            <FormHelperText
              component="legend"
              style={{
                color: "#f44336",
                paddingLeft: "0px",
                textAlign: "center",
              }}
            >
              {formik.touched.sponsoringChamberMemberId &&
                formik.errors.sponsoringChamberMemberId}
            </FormHelperText>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label={enumStrings.rewardCampaign.label.campaignName}
                className={classes.companyInput}
                variant="outlined"
                id="campaignName"
                name="campaignName"
                value={formik.values.campaignName}
                onChange={formik.handleChange}
                error={
                  formik.touched.campaignName &&
                  Boolean(formik.errors.campaignName)
                }
                helperText={
                  formik.touched.campaignName && formik.errors.campaignName
                }
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={enumStrings.rewardCampaign.tooltip.campaignName}
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <FormControl className={classes.notificationDescriptionInput}>
                <TextField
                  className={classes.selectedValue}
                  maxRows={5}
                  label={enumStrings.rewardCampaign.label.prizeDescription}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      maxLength: CHARACTER_LIMIT_DESCRIPTION,
                    },
                  }}
                  value={formik.values.prizeDescription}
                  onChange={formik.handleChange}
                  multiline
                  id="prizeDescription"
                  name="prizeDescription"
                />
              </FormControl>
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.rewardCampaign.tooltip.prizeDescription
                  }
                  showIt
                />
              </div>
            </div>
            <InputLabel style={{ margin: "auto" }}>
              {`${
                (formik.values.prizeDescription || "").length
              }/${CHARACTER_LIMIT_DESCRIPTION} characters remaining`}
            </InputLabel>
            <FormHelperText
              component="legend"
              style={{
                color: "#f44336",
                paddingLeft: "0px",
                paddingTop: "5px",
                textAlign: "center",
              }}
            >
              {formik.touched.prizeDescription &&
                formik.errors.prizeDescription}
            </FormHelperText>
            <div className={classes.descriptionPreviewText}>
              <h3>
                <span style={{ fontWeight: "bold", marginBottom: "15px" }}>
                  Preview of prize description in Chamber Perks App™:
                  <br />
                </span>
                "Earn ballots to win{" "}
                <span style={{ fontWeight: "bold" }}>
                  {formik.values.prizeDescription.length > 0
                    ? formik.values.prizeDescription
                    : "enter text above"}
                </span>
                "
              </h3>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <RewardCampaignEndDatePicker
                formikErrors={formik.errors.startDateUtcMilli}
                formikVal={formik.values.startDateUtcMilli}
                formikSetFieldValue={formik.setFieldValue}
                startAfterDate={intitalValuesMemo?.startDateUtcMilli || 0}
                isForStartDate
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.rewardCampaign.tooltip.startDateUtcMilli
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <RewardCampaignEndDatePicker
                formikErrors={formik.errors.endDateUtcMilli}
                formikVal={formik.values.endDateUtcMilli}
                formikSetFieldValue={formik.setFieldValue}
                disabled={!(formik.values.startDateUtcMilli > 0)}
                startAfterDate={formik.values.startDateUtcMilli}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.rewardCampaign.tooltip.endDateUtcMilli
                  }
                  showIt
                />
              </div>
            </div>
            <br />
            <br />
            <div>
              {invalidError ? (
                <div className={classes.invalidError}>{customError}</div>
              ) : null}
            </div>{" "}
            {Object.keys(formik?.errors || {}).length > 0 ? (
              <div>
                <br />
                <div className={classes.invalidError}>
                  Please address any issues in red to update
                </div>
              </div>
            ) : null}
            <br />
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#E1A731",
                fontSize: "23px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: "200px",
                marginTop: "59px",
                marginBottom: "39px",
                margin: "auto",
                height: "65px",
              }}
              disabled={buttonDisabled || loader}
            >
              {loader ? (
                <CircularProgress
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                "Update"
              )}
            </Button>
            <br />
            <Button
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#E1A731",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: "200px",
                marginTop: "59px",
                marginBottom: "39px",
                margin: "auto",
                height: "45px",
              }}
              onClick={() => setEndCampaignModalOpen(true)}
              disabled={buttonDisabled || loader}
            >
              End Campaign
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export const EndCampaignModal = ({
  closeModal,
  campaignId,
}: {
  closeModal: () => void;
  campaignId: string;
}): ReactElement => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);

  const [endCampaignExecution] = useEndRewardCampaignMutation();
  const redirectToRewardCampaignList = useRedirect(RedirectUri.chamberReward, {
    showToast: true,
    toastText: "Campaign Ended Successfully",
  });
  const endCampaign = useCallback(async () => {
    setLoader(true);
    await endCampaignExecution({
      variables: {
        id: campaignId,
      },
      refetchQueries: [
        { query: GetAllRewardCampaignsForChamberDocument },
        "getAllRewardCampaignsForChamber",
      ],
      awaitRefetchQueries: true,
    });
    closeModal();
    redirectToRewardCampaignList();
  }, [
    campaignId,
    closeModal,
    endCampaignExecution,
    redirectToRewardCampaignList,
  ]);

  return (
    <Modal className={classes.modal} open={true}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between", height: "450px" }}
      >
        <h1 style={{ fontWeight: "bold" }}>Are You Sure?</h1>
        <div
          style={{
            padding: "50px",
            paddingBottom: 0,
            paddingTop: 20,
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          <h3>
            Ending the campaign will remove the campaign from the Chamber Perks
            App and no additional Chamber Perks App™ users will be able to
            participate in the campaign prize draw.
            <br />
            <br />
            This campaign cannot be restarted after ending.
            <br />
            <br />
            If you are sure you want to end this campaign, click End. Otherwise,
            click Go Back.
          </h3>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.modalButton}
            onClick={() => {
              endCampaign();
            }}
            variant="contained"
            disabled={loader}
          >
            {loader ? <CircularProgress size={28} /> : "End"}
          </Button>
          <Button
            className={classes.modalButton}
            style={{
              backgroundColor: "#37474f",
              color: "#e9e9e9",
            }}
            onClick={closeModal}
            variant="contained"
            disabled={loader}
          >
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateRewardCampaignCampaign;
