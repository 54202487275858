import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  useGetAllChamberMembersQuery,
  UserType,
  useGetAllDeletedDiscountsFromChamberIdQuery,
  DiscountReturn,
  useGetAllCategoriesQuery,
  useGetAllDiscountsFromChamberIdQuery,
  ChamberMemberCategory,
  useGetSystemWidePerkStatsQuery,
  useGetAllContactableCompaniesForChamberQuery,
  ContactableCompanyReturn,
} from "../../../graphql/types";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import Loader from "../../common/Loader/Loader";
import ViewStatsBoxes from "../../common/ViewStatsBoxes";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";
import RootAdminStatTable from "../../common/RootAdminStatTable";
import { useHistory, useLocation } from "react-router-dom";
import useIsChamber from "../../common/Hooks/useIsChamber";
import CustomChamberEngageIcon from "../../../images/CustomChamberEngageIcon.png";

const formatMoney = (amount: number) => {
  // Round the amount to the nearest dollar
  const roundedAmount = Math.round(amount);

  // Format the rounded amount as currency without cents
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(roundedAmount);
};

const useStyles = makeStyles(() => ({
  viewStatsBoxes: {
    width: "100%",
    "@media (max-width:1630px)": {
      maxWidth: "988px",
    },
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "50px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  contentContainer: {
    display: "flex",
    minHeight: "80vh",
    flexWrap: "wrap",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
    marginTop: "50px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "135px",
    },
  },
  boxValue2: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
    marginTop: "168px",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  exportButton: {
    backgroundColor: "white",
    fontWeight: "bold",
    color: "#000000",
    borderRadius: "10px",
    width: "340px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  },
}));

export const getOfferedToText = (
  discount: DiscountReturn,
  isChamberMemberAdmin?: boolean
) => {
  if (discount.isPrivate) {
    return isChamberMemberAdmin
      ? "Your Company Employees Only"
      : "Member Company Employees Only";
  } else if (!discount.memberOnly) {
    return "Anyone";
  } else if (discount.memberOnly && !discount.isAvailableToAllMembers) {
    return "Local Members Only";
  } else if (discount.memberOnly && discount.isAvailableToAllMembers) {
    return "All Members";
  }

  return "";
};

const DiscountBreakDownPieChart = ({
  numOfAllMemberPerks,
  numofLocalMemberOnlyPerks,
  numOfGeneralPublicPerks,
  numOfCompanyEmployeesOnlyPerks,
}: {
  numOfAllMemberPerks: number;
  numofLocalMemberOnlyPerks: number;
  numOfGeneralPublicPerks: number;
  numOfCompanyEmployeesOnlyPerks: number;
}) => {
  if (
    numOfAllMemberPerks === 0 &&
    numofLocalMemberOnlyPerks === 0 &&
    numOfGeneralPublicPerks === 0 &&
    numOfCompanyEmployeesOnlyPerks === 0
  ) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
        }}
      >
        {enumStrings.rootAdminChamberText.createPerkToSeeChart}
      </div>
    );
  }

  const totalPerks =
    numOfAllMemberPerks +
    numofLocalMemberOnlyPerks +
    numOfGeneralPublicPerks +
    numOfCompanyEmployeesOnlyPerks;

  return (
    <PieChart
      series={[
        {
          valueFormatter: (item) =>
            `${Math.round((item.value / totalPerks) * 100)}%`,
          arcLabel: (item) => `${Math.round((item.value / totalPerks) * 100)}%`,
          arcLabelMinAngle: 40,
          innerRadius: 30,
          data: [
            {
              id: 0,
              value: numOfGeneralPublicPerks,
              label: `Anyone (${numOfGeneralPublicPerks})`,
            },
            {
              id: 1,
              value: numOfAllMemberPerks,
              label: `All Members (${numOfAllMemberPerks})`,
            },
            {
              id: 2,
              value: numofLocalMemberOnlyPerks,
              label: `Local Members (${numofLocalMemberOnlyPerks})`,
              color: "#E1A731",
            },
            {
              id: 3,
              value: numOfCompanyEmployeesOnlyPerks,
              label: `Member Company\nEmployees (${numOfCompanyEmployeesOnlyPerks})`,
            },
          ],
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
          fontSize: "20px",
        },
      }}
      margin={{ left: 10, bottom: 110, top: 0, right: 200 }}
      slotProps={{
        legend: {
          position: { vertical: "middle", horizontal: "right" },
          padding: { top: 0, bottom: 0, left: 0, right: 0 },
        },
      }}
      width={390}
      height={280}
    />
  );
};

const RootChamberAdmin = (): ReactElement => {
  const classes = useStyles();

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    // Function to scroll to the element
    const scrollToElement = () => {
      if (hash) {
        const element = document.getElementById("placeToScrollToChamberEngage");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    // Set timeout to delay the execution
    const timeoutId = setTimeout(scrollToElement, 500);

    // Clear the timeout if the component unmounts or location changes
    return () => clearTimeout(timeoutId);
  }, [location]);

  const userIdInAuthChamberInfo = useGetChamberFromUserIdInAuth();
  const getAllChamberMembers = useGetAllChamberMembersQuery({
    variables: {
      chamberId: userIdInAuthChamberInfo?.id || "",
      getHasSignedUp: true,
      getNumberOfUsers: true,
    },
    skip: !userIdInAuthChamberInfo?.id,
    fetchPolicy: "cache-and-network",
  });

  let { data: discountData } = useGetAllDiscountsFromChamberIdQuery({
    variables: { chamberId: userIdInAuthChamberInfo?.id || "" },
    skip: !userIdInAuthChamberInfo?.id,
    fetchPolicy: "cache-and-network",
  });

  let { data: deletedDiscountData } =
    useGetAllDeletedDiscountsFromChamberIdQuery({
      variables: { chamberId: userIdInAuthChamberInfo?.id || "" },
      skip: !userIdInAuthChamberInfo?.id,
      fetchPolicy: "cache-and-network",
    });

  const rootChamberAdminObj =
    enumStrings.groups[UserType.ChamberAdmin][RedirectUri.chamberAdmin];

  const { data: categoriesData } = useGetAllCategoriesQuery();

  let exportPerksCsvDiscountListData = useMemo(() => {
    const nonDeletedDiscounts = discountData?.getAllDiscountsFromChamberId?.map(
      (item) => ({
        memberName: item.chamberMemberInfo.name,
        discountName: item.title,
        offeredTo: getOfferedToText(item as any),
        discountViews: item.views || 0,
        discountRedemptions: item.numberOfRedemptions || 0,
        creationTimeUtc: item.createdAtUtcMilli
          ? new Date(item.createdAtUtcMilli).toUTCString()
          : "",
        deletionTimeUtc: "",
        category1:
          categoriesData?.getAllCategories?.find(
            ({ id }) => id === item.chamberMemberInfo.categoryId[0]
          )?.name || "",
        category2: item.chamberMemberInfo.categoryId[1]
          ? categoriesData?.getAllCategories?.find(
              ({ id }) => id === item.chamberMemberInfo.categoryId[1]
            )?.name || ""
          : "",
      })
    );

    const deletedDiscounts =
      deletedDiscountData?.getAllDeletedDiscountsFromChamberId?.map((item) => ({
        memberName: item.chamberMemberInfo.name,
        discountName: item.title,
        offeredTo: getOfferedToText(item as any),
        discountViews: item.views || 0,
        discountRedemptions: item.numberOfRedemptions || 0,
        creationTimeUtc: item.createdAtUtcMilli
          ? new Date(item.createdAtUtcMilli).toUTCString()
          : "",
        deletionTimeUtc: item.deletedAtUtcMilli
          ? new Date(item.deletedAtUtcMilli).toUTCString()
          : "",
        category1:
          categoriesData?.getAllCategories?.find(
            ({ id }) => id === item.chamberMemberInfo.categoryId[0]
          )?.name || "",
        category2: item.chamberMemberInfo.categoryId[1]
          ? categoriesData?.getAllCategories?.find(
              ({ id }) => id === item.chamberMemberInfo.categoryId[1]
            )?.name || ""
          : "",
      }));

    let totalDiscountViews = 0;
    let totalDiscountRedemptions = 0;

    const nonDeletedAndDeletedDiscounts = [
      ...(nonDeletedDiscounts || []),
      ...(deletedDiscounts || []),
    ];

    for (const discount of nonDeletedAndDeletedDiscounts) {
      totalDiscountViews += discount.discountViews;
      totalDiscountRedemptions += discount.discountRedemptions;
    }

    const totalDataArr = [
      {
        memberName: "TOTAL VIEWS AND REDEMPTIONS ACROSS ALL PERKS",
        discountName: "TOTAL VIEWS AND REDEMPTIONS ACROSS ALL PERKS",
        offeredTo: "",
        discountViews: totalDiscountViews,
        discountRedemptions: totalDiscountRedemptions,
        creationTimeUtc: "",
        deletionTimeUtc: "",
      },
    ];

    return [...totalDataArr, ...nonDeletedAndDeletedDiscounts];
  }, [
    categoriesData?.getAllCategories,
    deletedDiscountData?.getAllDeletedDiscountsFromChamberId,
    discountData?.getAllDiscountsFromChamberId,
  ]);

  const {
    numberOfPerksText,
    numberOfBusinessesOfferingPerksText,
    numOfAllMemberPerks,
    numOfLocalMemberOnlyPerks,
    numOfGeneralPublicPerks,
    numOfCompanyEmployeesOnlyPerks,
  } = useMemo(() => {
    let numberOfPerksText = null;
    let numberOfBusinessesOfferingPerksText = null;
    let numOfAllMemberPerks = 0;
    let numOfLocalMemberOnlyPerks = 0;
    let numOfGeneralPublicPerks = 0;
    let numOfCompanyEmployeesOnlyPerks = 0;

    if (!discountData) {
      return { numberOfPerksText, numberOfBusinessesOfferingPerksText };
    }

    if (!discountData.getAllDiscountsFromChamberId) {
      return {
        numberOfPerksText: 0,
        numberOfBusinessesOfferingPerksText: 0,
      };
    }

    const businessesOfferingPerksIds = [];
    numberOfPerksText = 0;

    for (const discount of discountData.getAllDiscountsFromChamberId) {
      numberOfPerksText += 1;
      businessesOfferingPerksIds.push(discount.chamberMemberInfo.id);

      if (discount.isPrivate) {
        numOfCompanyEmployeesOnlyPerks += 1;
      } else if (!discount.memberOnly) {
        numOfGeneralPublicPerks += 1;
      } else if (discount.memberOnly && !discount.isAvailableToAllMembers) {
        numOfLocalMemberOnlyPerks += 1;
      } else if (discount.memberOnly && discount.isAvailableToAllMembers) {
        numOfAllMemberPerks += 1;
      }
    }

    // const percentOfAllMemberPerks = Math.round(
    //   (numOfAllMemberPerks / (numberOfPerksText || 1)) * 100
    // );

    // const percentOfLocalMemberOnlyPerks = Math.round(
    //   (numOfLocalMemberOnlyPerks / (numberOfPerksText || 1)) * 100
    // );

    // const percentOfGeneralPublicPerks = Math.round(
    //   (numOfGeneralPublicPerks / (numberOfPerksText || 1)) * 100
    // );

    // const percentOfCompanyEmployeesOnlyPerks = Math.round(
    //   (numOfCompanyEmployeesOnlyPerks / (numberOfPerksText || 1)) * 100
    // );

    return {
      numberOfPerksText,
      numberOfBusinessesOfferingPerksText: [
        // eslint-disable-next-line
        // @ts-ignore
        ...new Set(businessesOfferingPerksIds),
      ].length,
      numOfAllMemberPerks,
      numOfLocalMemberOnlyPerks,
      numOfGeneralPublicPerks,
      numOfCompanyEmployeesOnlyPerks,
    };
  }, [discountData]);

  const numOfChamberMembers = useMemo(() => {
    return (getAllChamberMembers?.data?.getAllChamberMembers || []).length;
  }, [getAllChamberMembers?.data?.getAllChamberMembers]);

  const percentOfCompaniesOfferingLivePerks = useMemo(() => {
    return `${Math.round(
      ((numberOfBusinessesOfferingPerksText || 0) / numOfChamberMembers) * 100
    ).toFixed(0)}%`;
  }, [numOfChamberMembers, numberOfBusinessesOfferingPerksText]);

  const { data: systemWidePerksStatsData } = useGetSystemWidePerkStatsQuery();

  const perksPerCategoryBreakdown = useMemo(() => {
    if (
      !categoriesData?.getAllCategories ||
      !discountData?.getAllDiscountsFromChamberId
    ) {
      return [];
    }

    const livePerksPerMemberCategory: {
      [k: string]: {
        livePerks: number;
        rawPercentageNumber: number;
        categoryId: ChamberMemberCategory;
        categoryName: string;
      };
    } = {};

    for (const perk of discountData?.getAllDiscountsFromChamberId || []) {
      const perksChamberMembersCategory = perk.chamberMemberInfo.categoryId;
      for (const catId of perksChamberMembersCategory) {
        if (!(catId in livePerksPerMemberCategory)) {
          livePerksPerMemberCategory[catId] = {
            livePerks: 0,
            rawPercentageNumber: 0,
            categoryId: catId,
            categoryName:
              categoriesData?.getAllCategories.find(({ id }) => id === catId)
                ?.name || "",
          };
        }
        livePerksPerMemberCategory[catId].livePerks += 1;
      }
    }
    for (const catId in livePerksPerMemberCategory) {
      livePerksPerMemberCategory[catId].rawPercentageNumber = Math.round(
        (livePerksPerMemberCategory[catId].livePerks /
          (numberOfPerksText || 1)) *
          100
      );
    }

    for (const cat of categoriesData.getAllCategories || []) {
      if (!(cat.id in livePerksPerMemberCategory)) {
        livePerksPerMemberCategory[cat.id] = {
          categoryId: cat.id,
          categoryName: cat.name,
          livePerks: 0,
          rawPercentageNumber: 0,
        };
      }
    }

    // Convert the object into an array of key-value pairs
    const entries = Object.values(livePerksPerMemberCategory);

    // Sort the array based on the "bingo" property in descending order
    return entries.sort((a, b) => b.livePerks - a.livePerks);
  }, [
    categoriesData?.getAllCategories,
    discountData?.getAllDiscountsFromChamberId,
    numberOfPerksText,
  ]);

  const history = useHistory();

  const handleDiscountClick = useCallback(
    (
      discountInfo: Omit<DiscountReturn, "redeemSettings" | "numberOfFavorites">
    ) => {
      history.push(RedirectUri.editDiscountPageChamberAdmin, discountInfo);
    },
    [history]
  );

  const newestPerksChartData = useMemo(() => {
    if (!discountData?.getAllDiscountsFromChamberId) {
      return [];
    }

    return (discountData?.getAllDiscountsFromChamberId || [])
      .map((discount) => ({
        perkDataCompanyAndLogo: {
          logo: discount.chamberMemberInfo.logo,
          name: discount.chamberMemberInfo.name,
          handleDiscountClick: () => handleDiscountClick(discount),
        },
        discountTitle: discount.title,
        createdAtUtcMilli: discount.createdAtUtcMilli || 0,
      }))
      .sort(function (a, b) {
        return b.createdAtUtcMilli - a.createdAtUtcMilli;
      });
  }, [discountData?.getAllDiscountsFromChamberId, handleDiscountClick]);

  const mostPopularPerksChartData = useMemo(() => {
    if (!discountData?.getAllDiscountsFromChamberId) {
      return [];
    }

    return (discountData?.getAllDiscountsFromChamberId || [])
      .map((discount) => ({
        perkDataCompanyAndLogo: {
          logo: discount.chamberMemberInfo.logo,
          name: discount.chamberMemberInfo.name,
          handleDiscountClick: () => handleDiscountClick(discount),
        },
        discountTitle: discount.title,
        views: discount.views || 0,
      }))
      .sort(function (a, b) {
        return b.views - a.views;
      });
  }, [discountData?.getAllDiscountsFromChamberId, handleDiscountClick]);

  const isChamber = useIsChamber();

  const { data: rawContactableCompanyData } =
    useGetAllContactableCompaniesForChamberQuery();

  const contactableCompanyData: ContactableCompanyReturn[] | undefined =
    useMemo(() => {
      if (userIdInAuthChamberInfo?.isSandbox) {
        const genericCompanyNames = [
          "Apex Solutions",
          "InnovateTech Ventures",
          "GlobalEdge Enterprises",
          "Nexus Innovations",
          "Synergy Systems",
          "Quantum Strategies",
          "Optimal Solutions Group",
          "Stellar Innovations Inc.",
          "Fusion Technologies",
          "Prime Vision Corporation",
        ];

        return genericCompanyNames.map((name) => ({
          chamberId: userIdInAuthChamberInfo.id,
          chamberName: "bingo",
          id: `${name} - bingo`,
          name,
          createdAtUtcMilli: 0,
        })) as ContactableCompanyReturn[];
      }

      return rawContactableCompanyData?.getAllContactableCompaniesForChamber as
        | ContactableCompanyReturn[]
        | undefined;
    }, [
      rawContactableCompanyData,
      userIdInAuthChamberInfo?.id,
      userIdInAuthChamberInfo?.isSandbox,
    ]);

  return (
    <span>
      <div className={classes.titleContent}>
        <span className={classes.title}>
          {/* @ts-ignore */}
          {rootChamberAdminObj?.title}
          <PositionedTooltips
            //  @ts-ignore
            title={rootChamberAdminObj?.title}
            //  @ts-ignore
            description={rootChamberAdminObj?.toolTipDescription}
          />
        </span>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className={classes.viewStatsBoxes}>
          <ViewStatsBoxes
            disableTopMargin
            maxBoxesPerRow={4}
            boxes={[
              {
                smallBox: true,
                boxTitle: "",
                contentFullHeightAndWidth: true,
                boxContent: (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      fontSize: "24px",
                      color: "black",
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "10px",
                      paddingBottom: "15px",
                    }}
                  >
                    <div>
                      Your {isChamber ? "Chamber" : "Board"}'s Perk Breakdown
                    </div>
                    <div
                      style={{
                        flex: 1,
                        fontWeight: "normal",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {numberOfPerksText === null ||
                      numberOfPerksText === undefined ? (
                        <>
                          <Loader />
                          <br />
                        </>
                      ) : (
                        <DiscountBreakDownPieChart
                          numOfAllMemberPerks={
                            userIdInAuthChamberInfo?.isSandbox
                              ? 51
                              : numOfAllMemberPerks || 0
                          }
                          numofLocalMemberOnlyPerks={
                            userIdInAuthChamberInfo?.isSandbox
                              ? 92
                              : numOfLocalMemberOnlyPerks || 0
                          }
                          numOfGeneralPublicPerks={
                            userIdInAuthChamberInfo?.isSandbox
                              ? 117
                              : numOfGeneralPublicPerks || 0
                          }
                          numOfCompanyEmployeesOnlyPerks={
                            userIdInAuthChamberInfo?.isSandbox
                              ? 6
                              : numOfCompanyEmployeesOnlyPerks || 0
                          }
                        />
                      )}
                    </div>
                  </div>
                ),
              },
              {
                smallBox: true,
                boxTitle:
                  enumStrings.rootAdminChamberText.perksOfferedByYourMemebrs,
                boxContent: (
                  <>
                    {numberOfPerksText === null ||
                    numberOfPerksText === undefined ? (
                      <>
                        <Loader />
                        <br />
                      </>
                    ) : userIdInAuthChamberInfo?.isSandbox ? (
                      266
                    ) : (
                      numberOfPerksText
                    )}
                  </>
                ),
              },
              {
                smallText: true,
                smallBox: true,
                boxTitle: "Your Members Offering Perks",
                boxContent: (
                  <div>
                    {!getAllChamberMembers?.data ? (
                      <>
                        <Loader />
                        <br />
                      </>
                    ) : userIdInAuthChamberInfo?.isSandbox ? (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <span>156 | 21%</span>
                        <span style={{ color: "black", fontSize: "20px" }}>
                          Out of <span style={{ color: "#E1A731" }}>745</span>{" "}
                          members
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <span>
                          {numberOfBusinessesOfferingPerksText} |{" "}
                          {percentOfCompaniesOfferingLivePerks}
                        </span>
                        <span style={{ color: "black", fontSize: "20px" }}>
                          Out of{" "}
                          <span style={{ color: "#E1A731" }}>
                            {numOfChamberMembers}
                          </span>{" "}
                          members
                        </span>
                      </div>
                    )}
                  </div>
                ),
              },
              {
                smallBox: true,
                boxTitle: `Your ${
                  isChamber ? "Chamber" : "Board"
                }'s Total Perk Views`,
                boxContent: (
                  <>
                    {numberOfPerksText === null ||
                    numberOfPerksText === undefined ? (
                      <>
                        <Loader />
                        <br />
                      </>
                    ) : userIdInAuthChamberInfo?.isSandbox ? (
                      15256
                    ) : (
                      exportPerksCsvDiscountListData[0].discountViews
                    )}
                  </>
                ),
              },
            ]}
          />

          <ViewStatsBoxes
            disableTopMargin
            maxBoxesPerRow={4}
            boxes={[
              {
                smallBox: true,
                boxTitle: "",
                contentFullHeightAndWidth: true,
                boxContent: (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      fontSize: "24px",
                      color: "black",
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "10px",
                      paddingBottom: "15px",
                    }}
                  >
                    <div>Chamber Perks App Perk Breakdown</div>
                    <div
                      style={{
                        flex: 1,
                        fontWeight: "normal",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                        <>
                          <Loader />
                          <br />
                        </>
                      ) : (
                        <DiscountBreakDownPieChart
                          numOfAllMemberPerks={
                            systemWidePerksStatsData?.getSystemWidePerkStats
                              ?.numOfAllMemberPerks || 0
                          }
                          numofLocalMemberOnlyPerks={
                            systemWidePerksStatsData?.getSystemWidePerkStats
                              ?.numOfLocalMemberOnlyPerks || 0
                          }
                          numOfGeneralPublicPerks={
                            systemWidePerksStatsData?.getSystemWidePerkStats
                              ?.numOfGeneralPublicPerks || 0
                          }
                          numOfCompanyEmployeesOnlyPerks={
                            systemWidePerksStatsData?.getSystemWidePerkStats
                              ?.numOfCompanyEmployeesOnlyPerks || 0
                          }
                        />
                      )}
                    </div>
                  </div>
                ),
              },
              {
                smallBox: true,
                boxTitle: "Perks Available on Chamber Perks App",
                boxContent: (
                  <>
                    {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                      <>
                        <Loader />
                        <br />
                      </>
                    ) : userIdInAuthChamberInfo?.isSandbox ? (
                      (systemWidePerksStatsData?.getSystemWidePerkStats
                        .totalActivePerks || 0) + 300
                    ) : (
                      systemWidePerksStatsData?.getSystemWidePerkStats
                        .totalActivePerks
                    )}
                  </>
                ),
              },
              {
                smallBox: true,
                smallText: true,
                boxTitle: "Members Offering Perks on Chamber Perks App",
                boxContent: (
                  <>
                    {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                      <>
                        <Loader />
                        <br />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div style={{ height: "20px" }} />
                        <span>
                          {
                            systemWidePerksStatsData?.getSystemWidePerkStats
                              ?.numberOfMembersOfferingPerks
                          }{" "}
                          |{" "}
                          {
                            systemWidePerksStatsData?.getSystemWidePerkStats
                              ?.percentOfMembersOfferingPerks
                          }
                          %
                        </span>
                        <span style={{ color: "black", fontSize: "20px" }}>
                          Out of{" "}
                          <span style={{ color: "#E1A731" }}>
                            {
                              systemWidePerksStatsData?.getSystemWidePerkStats
                                ?.totalNumOfMembers
                            }
                          </span>{" "}
                          members
                        </span>
                      </div>
                    )}
                  </>
                ),
              },
              {
                smallBox: true,
                boxTitle: "Chamber Perks App Total Perk Views",
                boxContent: (
                  <>
                    {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                      <>
                        <Loader />
                        <br />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div style={{ height: "20px" }} />
                        <span>
                          {userIdInAuthChamberInfo?.isSandbox
                            ? "91589"
                            : systemWidePerksStatsData?.getSystemWidePerkStats
                                ?.totalPerkViews}
                        </span>
                        <span style={{ color: "black", fontSize: "20px" }}>
                          Out of{" "}
                          <span style={{ color: "#E1A731" }}>
                            {userIdInAuthChamberInfo?.isSandbox
                              ? "40"
                              : systemWidePerksStatsData?.getSystemWidePerkStats
                                  ?.totalNumOfChambers}
                          </span>{" "}
                          chambers
                        </span>
                      </div>
                    )}
                  </>
                ),
              },
            ]}
          />
          <ViewStatsBoxes
            disableTopMargin
            maxBoxesPerRow={3}
            boxes={[
              {
                boxTitle: `Perks Category Breakdown for Your ${
                  isChamber ? "Chamber" : "Board"
                }`,
                mediumBox: true,
                boxContent: (
                  <>
                    {discountData?.getAllDiscountsFromChamberId ===
                    undefined ? (
                      <Loader />
                    ) : discountData?.getAllDiscountsFromChamberId === null ? (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "20px",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {enumStrings.rootAdminChamberText.createPerkToSeeChart}
                      </div>
                    ) : (
                      <RootAdminStatTable
                        headCells={[
                          {
                            id: "categoryName",
                            label: "Category",
                            splitOn: "/",
                          },
                          {
                            id: "rawPercentageNumber",
                            label: "% of Perks",
                            isPercent: true,
                            defaultSort: true,
                            sortOrder: "desc",
                          },
                        ]}
                        loading={(perksPerCategoryBreakdown || []).length === 0}
                        allChartData={perksPerCategoryBreakdown}
                      />
                    )}
                  </>
                ),
              },
              {
                boxTitle: "Most Popular Perks Offered by Your Members",
                mediumBox: true,
                boxContent: (
                  <>
                    {discountData?.getAllDiscountsFromChamberId ===
                    undefined ? (
                      <Loader />
                    ) : discountData?.getAllDiscountsFromChamberId === null ? (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "20px",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {enumStrings.rootAdminChamberText.createPerkToSeeChart}
                      </div>
                    ) : (
                      <RootAdminStatTable
                        headCells={[
                          {
                            id: "perkDataCompanyAndLogo",
                            label: "Company Name",
                            perkDataCompanyAndLogo: true,
                            splitOn: "/",
                          },
                          {
                            id: "discountTitle",
                            label: "Perk title",
                            splitOn: "/",
                          },
                        ]}
                        loading={(mostPopularPerksChartData || []).length === 0}
                        allChartData={mostPopularPerksChartData}
                      />
                    )}
                  </>
                ),
              },
              {
                boxTitle: "Newest Perks Offered by Your Members",
                mediumBox: true,
                boxContent: (
                  <>
                    {discountData?.getAllDiscountsFromChamberId ===
                    undefined ? (
                      <Loader />
                    ) : discountData?.getAllDiscountsFromChamberId === null ? (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "20px",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {enumStrings.rootAdminChamberText.createPerkToSeeChart}
                      </div>
                    ) : (
                      <RootAdminStatTable
                        headCells={[
                          {
                            id: "perkDataCompanyAndLogo",
                            label: "Company Name",
                            perkDataCompanyAndLogo: true,
                            splitOn: "/",
                          },
                          {
                            id: "discountTitle",
                            label: "Perk title",
                            splitOn: "/",
                          },
                        ]}
                        loading={(newestPerksChartData || []).length === 0}
                        allChartData={newestPerksChartData}
                      />
                    )}
                  </>
                ),
              },
              {
                boxTitle: "Company Leads from\nChamber Perks App™",
                mediumBox: true,
                boxSubTitle: `The companies listed below were provided by app users who selected your ${
                  isChamber ? "Chamber" : "Board"
                } and did not see their employer listed. Please follow up with these companies to see if they are interested in joining your ${
                  isChamber ? "Chamber" : "Board"
                } and the benefits of the Chamber Perks App™ for their employees.`,
                boxContent: (
                  <>
                    {contactableCompanyData === undefined ? (
                      <Loader />
                    ) : contactableCompanyData === null ? (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "20px",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        No leads yet
                      </div>
                    ) : (
                      <RootAdminStatTable
                        chartSize="small"
                        headCells={[
                          {
                            id: "name",
                            label: "Company Lead",
                          },
                          {
                            id: "createdAtUtcMilli",
                            label: "Submission Date",
                            convertUtcToTimestamp: true,
                            onlyShowDate: true,
                            defaultSort: true,
                            sortOrder: "desc",
                          },
                        ]}
                        loading={(contactableCompanyData || []).length === 0}
                        allChartData={contactableCompanyData || []}
                      />
                    )}
                  </>
                ),
              },
            ]}
          />
          {userIdInAuthChamberInfo?.chamberEngageSettings?.isActive && (
            <div>
              <div
                style={{
                  borderTop: "1px solid #C8C8C8",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  paddingTop: 15,
                  paddingBottom: 15,
                }}
              >
                <img
                  id="placeToScrollToChamberEngage"
                  alt="CustomChamberEngageIcon"
                  src={CustomChamberEngageIcon}
                  style={{
                    maxWidth: "250px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <ViewStatsBoxes
                disableTopMargin
                maxBoxesPerRow={3}
                boxes={[
                  {
                    smallBox: true,
                    boxTitle: "Total Revenue from Category Advertising Banners",
                    boxContent: (
                      <>
                        {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                          <>
                            <Loader />
                            <br />
                          </>
                        ) : (
                          <>
                            {formatMoney(
                              systemWidePerksStatsData?.getSystemWidePerkStats
                                .totalLifeTimeCategoryAdvertisingBannersRevenueForCurrentChamber
                            )}
                          </>
                        )}
                      </>
                    ),
                  },
                  {
                    smallBox: true,
                    boxTitle:
                      "Total Revenue from Enhanced Member Profile & Perks Banners",
                    boxContent: (
                      <>
                        {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                          <>
                            <Loader />
                            <br />
                          </>
                        ) : (
                          <>
                            {formatMoney(
                              systemWidePerksStatsData?.getSystemWidePerkStats
                                .totalLifeTimeRevenueFromMemberProfileAndPerksBannersForCurrentChamber
                            )}
                          </>
                        )}
                      </>
                    ),
                  },
                  {
                    smallBox: true,
                    boxTitle:
                      "Members with Activated Member Profile & Perk Banners",
                    boxContent: (
                      <>
                        {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                          <>
                            <Loader />
                            <br />
                          </>
                        ) : (
                          <>
                            {
                              systemWidePerksStatsData?.getSystemWidePerkStats
                                .totalNumOfMembersWithActiveMemberProfileAndPerksBannersForCurrentChamber
                            }
                          </>
                        )}
                      </>
                    ),
                  },

                  {
                    boxTitle: `Number of Members Per Category with Activated Member Profile & Perk Banners`,
                    mediumBox: true,
                    boxContent: (
                      <div>
                        {!systemWidePerksStatsData?.getSystemWidePerkStats ? (
                          <>
                            <Loader />
                            <br />
                          </>
                        ) : (
                          <RootAdminStatTable
                            headCells={[
                              {
                                id: "categoryId",
                                label: "Category",
                                splitOn: "/",
                              },
                              {
                                id: "totalNumOfMembers",
                                label: "# of Members",
                                defaultSort: true,
                                sortOrder: "desc",
                              },
                            ]}
                            loading={false}
                            allChartData={
                              systemWidePerksStatsData?.getSystemWidePerkStats
                                ?.totalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersForCurrentChamber
                            }
                          />
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </span>
  );
};

export default RootChamberAdmin;
