import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { UserType } from "../../../graphql/types";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginRight: "24px",
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    flexGrow: 1,
    height: "85vh",
    borderRadius: "16px",
    margin: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    flex: "1",
    marginTop: 0,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ChamberAdminManualAndLaunchGuide = (): ReactElement => {
  const classes = useStyles();

  let rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.ChamberAdmin][
      RedirectUri.chamberAdminManualLaunchGuide
    ];

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
        </div>
      </div>
      {/* 1. change the "support page" for chamber members: switch the positions of the two emails... update the description of the "chamber support email" to also show "to change you email username" */}
      <div className={classes.content}>
        <iframe
          allowFullScreen
          title="CPA User Guide"
          scrolling="no"
          src="https://heyzine.com/flip-book/2e97bec654.html"
          width="100%"
          height="100%"
          style={{ border: "none", borderRadius: "16px" }}
        />
      </div>
    </span>
  );
};

export default ChamberAdminManualAndLaunchGuide;
