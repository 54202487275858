import React, {
  useState,
  useEffect,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import {
  makeStyles,
  styled,
  Switch,
  SwitchProps,
  Radio,
  Tooltip,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import { toast, ToastContainer } from "react-toastify";
import {
  useGetAllChamberEngageInvoiceDataQuery,
  useExcludeChamberEngageActivationFromInvoiceMutation,
  useIncludeChamberEngageActivationInInvoiceMutation,
  useMarkChamberEngageActivationInvoicedMutation,
  ChamberEngageActivationTypeEnum,
  ChamberEngageMonthlyInvoiceChamberDataReturn,
  Query,
} from "../../../graphql/types";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import Loader from "../../common/Loader/Loader";
import {
  CheckCircle,
  Cancel,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Edit as EditIcon,
} from "@material-ui/icons";
import dayjs, { Dayjs } from "dayjs";
import { visuallyHidden } from "@mui/utils";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { PickersLayoutProps } from "@mui/x-date-pickers/PickersLayout";
import { DateView } from "@mui/x-date-pickers/models";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation } from "../../../graphql/types";
import { useGetCategoryIdToHumanReadableName } from "../../common/Hooks/useGetCategoryIdToHumanReadableName";
import ConfirmationDialog from "../../common/ConfirmationDialog";

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// @ts-ignore
const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    marginTop: "33px",
    flexDirection: "column",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "100%",
    borderRadius: "5px",
    textAlign: "center",
    maxHeight: "800px",
    width: "740px",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  yesButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  okButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#37474f",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  radio: {
    color: "#E1A731!important",
    padding: 0,
    marginRight: 10,
    backgroundColor: "transparent",
  },
  filledDatePicker: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    height: "55px",
    width: "100%",
    "& .MuiInputBase-root": {
      backgroundColor: "white !important",
      borderRadius: "15px !important",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "#37474f",
    },
    "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
      color: "black",
      opacity: 1,
      "-webkit-text-fill-color": "black",
    },
  },
  dropDownTableCell: {
    fontSize: "16px",
    borderBottom: "0px !important",
  },
  cell: {
    fontSize: "16px",
  },
  cellHeader: {
    fontSize: "16px",
    fontWeight: "bold !important",
    whiteSpace: "break-spaces",
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
  loader: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  table: {
    minWidth: 750,
    maxWidth: 1300,
    margin: "auto",
  },
  level1: {
    backgroundColor: "#F1F2F4",
  },
  level2: {
    backgroundColor: "#e0e0e0",
  },
}));

interface ExpandedRowState {
  [key: string]: boolean;
}

type Order = "asc" | "desc";

type AllChamberEngageInvoicesForTableType = (NonNullable<
  Query["getAllChamberEngageInvoiceData"]
>[0] & {
  yearMonthNumCombined: number;
  containsExcludedActivation: boolean;
})[];

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
}

interface FullPageChamberEngageInvoicesTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllChamberEngageInvoicesForTableType,
  comparator: (a: any, b: any) => number
): AllChamberEngageInvoicesForTableType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
  {
    id: "openCloseIcon",
    label: "",
  },
  {
    id: "yearMonthNumCombined",
    label: "Month & Year",
  },
  {
    id: "totalActivationFeeInDollars",
    label: "Total Activation Fees",
  },
  {
    id: "allInvoicesSentInMonth",
    label: "All Invoices Sent",
  },
];

const ShowInAppSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));

function FullPageChamberEngageInvoicesTableHead(
  props: FullPageChamberEngageInvoicesTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" style={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const FullPageManageAllChamberEngageInvoicesTable = ({
  allChamberEngageInvoices,
}: {
  allChamberEngageInvoices: AllChamberEngageInvoicesForTableType;
}): ReactElement => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("yearMonthNumCombined");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () => stableSort(allChamberEngageInvoices, getComparator(order, orderBy)),
    [allChamberEngageInvoices, order, orderBy]
  );

  const [expandedRows, setExpandedRows] = useState<ExpandedRowState>({});

  const toggleRow = useCallback((rowId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  }, []);

  const renderExpandIcon = useCallback(
    (rowId: string) =>
      expandedRows[rowId] ? <KeyboardArrowDown /> : <KeyboardArrowRight />,
    [expandedRows]
  );

  const renderInvoiceStatus = useCallback(
    (sent: boolean) =>
      sent ? (
        <CheckCircle className={classes.checkIcon} />
      ) : (
        <Cancel className={classes.cancelIcon} />
      ),
    [classes.checkIcon, classes.cancelIcon]
  );

  // Add this calculation
  const totalActivationFees = useMemo(
    () =>
      visibleRows.reduce(
        (sum, invoice) => sum + invoice.totalActivationFeeInDollars,
        0
      ),
    [visibleRows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{ minWidth: 750, maxWidth: 1300, margin: "auto" }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageChamberEngageInvoicesTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((chamberEngageInvoice) => (
                <>
                  <TableRow
                    onClick={() =>
                      toggleRow(
                        `${chamberEngageInvoice.year}-${chamberEngageInvoice.monthNum}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                    key={`${chamberEngageInvoice.year}-${chamberEngageInvoice.monthNum}`}
                  >
                    <TableCell style={{ width: 50 }}>
                      {renderExpandIcon(
                        `${chamberEngageInvoice.year}-${chamberEngageInvoice.monthNum}`
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>
                          {dayjs()
                            .month(chamberEngageInvoice.monthNum - 1)
                            .format("MMMM")}{" "}
                          {chamberEngageInvoice.year}
                        </span>
                        {chamberEngageInvoice.containsExcludedActivation ? (
                          <span style={{ color: "grey" }}>
                            {" "}
                            (contains excluded activation)
                          </span>
                        ) : null}
                      </span>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      ${chamberEngageInvoice.totalActivationFeeInDollars}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderInvoiceStatus(
                        chamberEngageInvoice.allInvoicesSentInMonth
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={
                          expandedRows[
                            `${chamberEngageInvoice.year}-${chamberEngageInvoice.monthNum}`
                          ]
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Table className={classes.level1}>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={classes.cellHeader}
                                ></TableCell>
                                <TableCell className={classes.cellHeader}>
                                  Chamber/Board
                                </TableCell>
                                <TableCell className={classes.cellHeader}>
                                  Featured Members{`\n`}Total Revenue
                                </TableCell>
                                <TableCell className={classes.cellHeader}>
                                  Category Advertising{`\n`}Total Revenue
                                </TableCell>
                                <TableCell className={classes.cellHeader}>
                                  Total Revenue
                                </TableCell>
                                <TableCell className={classes.cellHeader}>
                                  Invoice Sent Date
                                </TableCell>
                                <TableCell
                                  className={classes.cellHeader}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {chamberEngageInvoice.chamberData?.map(
                                (chamber) => (
                                  <ChamberDataBreakdownTable
                                    key={chamber.chamberId}
                                    chamber={chamber}
                                    expandedRows={expandedRows}
                                    toggleRow={toggleRow}
                                    classes={classes}
                                    monthYear={`${chamberEngageInvoice.year}-${chamberEngageInvoice.monthNum}`}
                                  />
                                )
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}

              <TableRow
                sx={{
                  borderTop: "2px solid rgba(224, 224, 224, 1)",
                  "& td": {
                    fontWeight: "bold",
                    fontSize: "16px",
                  },
                }}
              >
                <TableCell />
                <TableCell>Sum of all activation fees</TableCell>
                <TableCell>${totalActivationFees}</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

const CustomLayout = (
  props: PickersLayoutProps<Dayjs | null, Dayjs, DateView> & {
    setDatePickerOpen: (open: boolean) => void;
  }
) => {
  const { onClose, setDatePickerOpen } = props;

  return (
    <div>
      {props.children}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px",
        }}
      >
        <Button
          onClick={() => {
            setDatePickerOpen(false);
            onClose?.();
          }}
          sx={{
            color: "#E1A731",
            "&:hover": {
              backgroundColor: "rgba(225, 167, 49, 0.04)",
            },
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

interface EditFeeDialogProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (fee: number) => void;
  currentFee: number;
}

const EditFeeDialog = ({
  open,
  onClose,
  onUpdate,
  currentFee,
}: EditFeeDialogProps) => {
  const [selectedFee, setSelectedFee] = useState<number>(currentFee);
  const classes = useStyles();

  // Update selectedFee when currentFee changes
  useEffect(() => {
    setSelectedFee(currentFee);
  }, [currentFee]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        unmountOnExit: true,
      }}
      PaperProps={{
        style: {
          backgroundColor: "#37474f",
          color: "#ffffff",
          borderRadius: "8px",
          padding: "8px",
        },
      }}
    >
      <DialogTitle style={{ color: "#ffffff" }}>Update Revenue</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            minWidth: "300px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedFee === 100}
              onChange={() => setSelectedFee(100)}
              className={classes.radio}
              size="small"
            />
            <div>
              <div style={{ fontWeight: "bold", color: "#ffffff" }}>$100</div>
              <div style={{ color: "#e0e0e0" }}>Sold by CPA</div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedFee === 50}
              onChange={() => setSelectedFee(50)}
              className={classes.radio}
              size="small"
            />
            <div>
              <div style={{ fontWeight: "bold", color: "#ffffff" }}>$50</div>
              <div style={{ color: "#e0e0e0" }}>Sold by Chamber</div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#e0e0e0" }}>
          Cancel
        </Button>
        <Button
          onClick={() => onUpdate(selectedFee)}
          disabled={selectedFee === currentFee}
          style={{
            backgroundColor: selectedFee === currentFee ? "#666" : "#E1A731",
            color: "#ffffff",
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ChamberDataBreakdownTable = ({
  chamber,
  expandedRows,
  toggleRow,
  classes,
  monthYear,
}: {
  chamber: ChamberEngageMonthlyInvoiceChamberDataReturn;
  expandedRows: ExpandedRowState;
  toggleRow: (rowId: string) => void;
  classes: any;
  monthYear: string;
}): ReactElement => {
  const chamberId = `${monthYear}-${chamber.chamberId}`;

  const [includeActivation] =
    useIncludeChamberEngageActivationInInvoiceMutation();
  const [excludeActivation] =
    useExcludeChamberEngageActivationFromInvoiceMutation();
  const [markInvoiced] = useMarkChamberEngageActivationInvoicedMutation();
  const [dateConfirmation, setDateConfirmation] = useState<{
    open: boolean;
    date: Dayjs | null;
    activations: { id: string; type: ChamberEngageActivationTypeEnum }[];
  }>({
    open: false,
    date: null,
    activations: [],
  });

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleDateChange = (
    date: Dayjs | null,
    activations: { id: string; type: ChamberEngageActivationTypeEnum }[]
  ) => {
    if (date) {
      const utcDate = date.utc().startOf("day");
      setDateConfirmation({
        open: true,
        date: utcDate,
        activations,
      });
      setDatePickerOpen(true);
    }
  };

  const [displayedDate, setDisplayedDate] = useState<number | null | undefined>(
    chamber.activations?.[0]?.invoiceSentAtUtcMilli || null
  );

  const handleDateConfirm = async () => {
    const { date, activations } = dateConfirmation;
    if (activations.length > 0) {
      try {
        await markInvoiced({
          variables: {
            activationsToMarkInvoiced: activations.map(({ id, type }) => ({
              activationId: id,
              activationType: type,
              invoiceSentAtUtcMilli: date?.valueOf(),
            })),
          },
          refetchQueries: ["getAllChamberEngageInvoiceData"],
          awaitRefetchQueries: true,
        });

        setDisplayedDate(date?.valueOf());
        toast.success("Invoice date updated successfully");
      } catch (error) {
        toast.error("Failed to update invoice date");
      }
      setDatePickerOpen(false);
      setDateConfirmation((prev) => ({ ...prev, open: false }));
    }
  };

  const handleDateDialogClose = () => {
    setDateConfirmation((prev) => ({ ...prev, open: false }));
  };

  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    activationId: "",
    activationType:
      ChamberEngageActivationTypeEnum.MemberProfileAndPerksBanners,
    newInclusionBool: false,
  });

  const handleCloseModal = () => {
    setConfirmationModal({
      open: false,
      activationId: "",
      activationType:
        ChamberEngageActivationTypeEnum.MemberProfileAndPerksBanners,
      newInclusionBool: false,
    });
  };

  const handleToggleInclusion = useCallback(async () => {
    const { activationId, activationType, newInclusionBool } =
      confirmationModal;

    if (activationId) {
      if (newInclusionBool) {
        await includeActivation({
          variables: { activationId, activationType },
          awaitRefetchQueries: true,
          refetchQueries: ["getAllChamberEngageInvoiceData"],
        });
        toast.success("Activation included in invoice successfully");
      } else {
        await excludeActivation({
          variables: { activationId, activationType },
          awaitRefetchQueries: true,
          refetchQueries: ["getAllChamberEngageInvoiceData"],
        });
        toast.success("Activation excluded from invoice successfully");
      }
    }

    handleCloseModal();
  }, [confirmationModal, includeActivation, excludeActivation]);

  const handleClearDate = () => {
    setDateConfirmation({
      open: true,
      date: null,
      activations:
        chamber.activations?.map((a) => ({
          id: a.activationId,
          type: a.activationType,
        })) || [],
    });
  };

  const [editingActivation, setEditingActivation] = useState<{
    id: string;
    currentFee: number;
  } | null>(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [pendingFeeUpdate, setPendingFeeUpdate] = useState<number | null>(null);

  const [updateActivationFee] =
    useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation();

  const handleUpdateFee = async () => {
    if (editingActivation && pendingFeeUpdate !== null) {
      try {
        await updateActivationFee({
          variables: {
            activationId: editingActivation.id,
            newActivationFeeInDollars: pendingFeeUpdate,
          },
          refetchQueries: ["getAllChamberEngageInvoiceData"],
          awaitRefetchQueries: true,
        });
        toast.success("Revenue updated successfully");
      } catch (error) {
        toast.error("Failed to update revenue");
      }
      setConfirmationOpen(false);
      setEditingActivation(null);
      setPendingFeeUpdate(null);
    }
  };

  const { categoryIdToHumanReadableName } =
    useGetCategoryIdToHumanReadableName();

  return (
    <>
      <TableRow
        onClick={() => toggleRow(chamberId)}
        style={{ cursor: "pointer" }}
      >
        <TableCell style={{ paddingLeft: theme.spacing(4) }}>
          {expandedRows[chamberId] ? (
            <KeyboardArrowDown />
          ) : (
            <KeyboardArrowRight />
          )}
        </TableCell>
        <TableCell className={classes.cell}>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <span>{chamber.chamberName}</span>
            {chamber.activations?.some(
              (activation) => !activation.includeInInvoice
            ) ? (
              <span style={{ color: "grey" }}>
                (contains excluded activation)
              </span>
            ) : null}
          </span>
        </TableCell>
        <TableCell className={classes.cell}>
          ${chamber.memberProfileAndPerksBannersTotalActivationFeeInDollars}
        </TableCell>
        <TableCell className={classes.cell}>
          ${chamber.categoryAdvertisingTotalActivationFeeInDollars}
        </TableCell>
        <TableCell className={classes.cell}>
          $
          {chamber.memberProfileAndPerksBannersTotalActivationFeeInDollars +
            chamber.categoryAdvertisingTotalActivationFeeInDollars}
        </TableCell>
        <TableCell
          onClick={(e) => e.stopPropagation()}
          className={classes.cell}
        >
          <div style={{ position: "relative", display: "inline-block" }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en"
              dateLibInstance={dayjs.utc}
            >
              <MobileDatePicker
                open={datePickerOpen}
                onOpen={() => setDatePickerOpen(true)}
                value={displayedDate ? dayjs.utc(displayedDate) : null}
                closeOnSelect={false}
                onChange={(date) =>
                  handleDateChange(
                    date,
                    chamber.activations?.map((a) => ({
                      id: a.activationId,
                      type: a.activationType,
                    })) || []
                  )
                }
                format="MMM DD, YYYY"
                timezone="UTC"
                slots={{
                  layout: (
                    layoutProps: PickersLayoutProps<
                      Dayjs | null,
                      Dayjs,
                      DateView
                    >
                  ) => CustomLayout({ ...layoutProps, setDatePickerOpen }),
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    placeholder: "MMM DD, YYYY",
                    style: {
                      width: 150,
                      backgroundColor: "white",
                    },
                    sx: {
                      "& .MuiInputBase-input.Mui-readOnly.MuiInputBase-readOnly":
                        {
                          cursor: "pointer",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E1A731 !important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E1A731 !important",
                      },
                    },
                  },
                  field: {
                    readOnly: true,
                    value: displayedDate ? dayjs.utc(displayedDate) : null,
                  },
                }}
                onAccept={() => {}}
              />
            </LocalizationProvider>
            {displayedDate && (
              <div
                onClick={handleClearDate}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  fontSize: "14px",
                  color: "#666",
                  padding: "4px",
                }}
              >
                ✕
              </div>
            )}
          </div>
        </TableCell>
        <TableCell />
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expandedRows[chamberId]} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginLeft: "90px" }}>
              <Table className={classes.level2}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cellHeader}>
                      Member Name
                    </TableCell>
                    <TableCell className={classes.cellHeader}>
                      Activation Type
                    </TableCell>
                    <TableCell className={classes.cellHeader}>Term</TableCell>
                    <TableCell className={classes.cellHeader}>
                      Revenue
                    </TableCell>
                    <TableCell className={classes.cellHeader}>
                      Exclude/Include
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chamber.activations?.map((activation) => (
                    <TableRow key={activation.activationId}>
                      <TableCell className={classes.cell}>
                        {activation.chamberMemberName}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {activation.activationType ===
                          ChamberEngageActivationTypeEnum.MemberProfileAndPerksBanners
                            ? "Featured Member"
                            : "Category Advertising"}
                          {activation.activationType ===
                            ChamberEngageActivationTypeEnum.CategoryAdvertising && (
                            <span style={{ color: "#666666" }}>
                              (
                              {activation.categoryAdvertisingCategoryId
                                ? categoryIdToHumanReadableName?.[
                                    activation.categoryAdvertisingCategoryId ||
                                      ""
                                  ]
                                : "Unknown Category"}
                              )
                            </span>
                          )}
                        </span>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {activation.activationLengthInMonths} months
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          ${activation.activationFeeInDollars}
                          {activation.activationType ===
                            ChamberEngageActivationTypeEnum.MemberProfileAndPerksBanners && (
                            <Tooltip
                              title={
                                activation.invoiceSentAtUtcMilli
                                  ? "Revenue cannot be changed after invoice sent date is set. Remove invoice sent date to change fee."
                                  : !activation.includeInInvoice
                                  ? "Revenue cannot be changed while activation is excluded. Include activation to change fee."
                                  : ""
                              }
                              placement="top"
                            >
                              <span>
                                {" "}
                                <EditIcon
                                  style={{
                                    cursor:
                                      activation.invoiceSentAtUtcMilli ||
                                      !activation.includeInInvoice
                                        ? "not-allowed"
                                        : "pointer",
                                    fontSize: "18px",
                                    color:
                                      activation.invoiceSentAtUtcMilli ||
                                      !activation.includeInInvoice
                                        ? "#999"
                                        : "#666",
                                    opacity:
                                      activation.invoiceSentAtUtcMilli ||
                                      !activation.includeInInvoice
                                        ? 0.5
                                        : 1,
                                  }}
                                  onClick={(e) => {
                                    if (
                                      !activation.invoiceSentAtUtcMilli &&
                                      activation.includeInInvoice
                                    ) {
                                      e.stopPropagation();
                                      setEditingActivation({
                                        id: activation.activationId,
                                        currentFee:
                                          activation.activationFeeInDollars,
                                      });
                                    }
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Box display="flex" alignItems="center">
                          <span>exclude</span>
                          <ShowInAppSwitch
                            checked={activation.includeInInvoice}
                            onChange={(e) => {
                              setConfirmationModal({
                                open: true,
                                activationId: activation.activationId,
                                activationType: activation.activationType,
                                newInclusionBool: e.target.checked,
                              });
                            }}
                          />
                          <span>include</span>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <ConfirmationDialog
        open={confirmationModal.open}
        title="Confirm Action"
        message={`Are you sure you want to ${
          confirmationModal.newInclusionBool ? "include" : "exclude"
        } this activation?`}
        onConfirm={handleToggleInclusion}
        onClose={handleCloseModal}
      />

      <ConfirmationDialog
        open={dateConfirmation.open}
        title={
          dateConfirmation.date ? "Confirm Date Change" : "Confirm Date Clear"
        }
        message={
          dateConfirmation.date
            ? `Are you sure you want to set the invoice date to ${dateConfirmation.date.format(
                "MMM DD, YYYY"
              )}?`
            : "Are you sure you want to clear the invoice sent date?"
        }
        onConfirm={handleDateConfirm}
        onClose={handleDateDialogClose}
      />

      <EditFeeDialog
        open={editingActivation !== null}
        onClose={() => setEditingActivation(null)}
        onUpdate={(fee) => {
          setPendingFeeUpdate(fee);
          setConfirmationOpen(true);
        }}
        currentFee={editingActivation?.currentFee ?? 0}
      />

      <ConfirmationDialog
        open={confirmationOpen}
        title="Confirm Fee Update"
        message={`Are you sure you want to update the revenue to $${pendingFeeUpdate}?`}
        onConfirm={handleUpdateFee}
        onClose={() => {
          setConfirmationOpen(false);
          setPendingFeeUpdate(null);
        }}
      />
    </>
  );
};

const ChamberEngageInvoicing = (): ReactElement => {
  const classes = useStyles();

  const {
    data: allChamberEngageInvoiceData,
    loading: allChamberEngageInvoiceDataLoading,
  } = useGetAllChamberEngageInvoiceDataQuery({
    fetchPolicy: "network-only",
  });

  const [isLoading, setIsLoading] = useState(true);

  const [allChamberEngageInvoices, setAllChamberEngageInvoices] =
    useState<AllChamberEngageInvoicesForTableType>([]);

  useEffect(() => {
    const getAllChamberEngageInvoices = async () => {
      if (!allChamberEngageInvoiceData?.getAllChamberEngageInvoiceData) {
        setIsLoading(true);
        return;
      }

      const allChamberEngageInvoices: AllChamberEngageInvoicesForTableType = [];

      for (const chamberEngageInvoice of allChamberEngageInvoiceData.getAllChamberEngageInvoiceData) {
        allChamberEngageInvoices.push({
          ...chamberEngageInvoice,
          yearMonthNumCombined:
            chamberEngageInvoice.year + chamberEngageInvoice.monthNum,
          containsExcludedActivation:
            chamberEngageInvoice.chamberData?.some((chamber) =>
              chamber.activations?.some(
                (activation) => !activation.includeInInvoice
              )
            ) ?? false,
        });
      }

      setAllChamberEngageInvoices(
        allChamberEngageInvoices.sort((a, b) => {
          return b.yearMonthNumCombined - a.yearMonthNumCombined;
        })
      );

      setIsLoading(false);
    };

    getAllChamberEngageInvoices();
  }, [
    allChamberEngageInvoiceData?.getAllChamberEngageInvoiceData,
    allChamberEngageInvoiceDataLoading,
  ]);

  const rootAdminObj = // @ts-ignore
    enumStrings.groups.ROOT_ADMIN[RedirectUri.rootChamberEngageInvoicing];

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <div style={{ height: 20 }} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {allChamberEngageInvoices &&
            (allChamberEngageInvoices || []).length > 0 ? (
              <FullPageManageAllChamberEngageInvoicesTable
                allChamberEngageInvoices={allChamberEngageInvoices}
              />
            ) : (
              <div
                style={{
                  color: "black",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                Loading Chamber Engage Invoices
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChamberEngageInvoicing;
